
import * as __star__ from '@clayui/multi-select';

const {
default: __default__,
itemLabelFilter,
__esModule
} = __star__;

export {
itemLabelFilter,
__esModule
};
export default __default__;
